import React
// , { useState, userEffect } 
from 'react';
import ResClientApi from '../services/restClientApi';
import UserService from '../services/user-service';
// import Pagination from "react-js-pagination";
import { Formik
  // , Form, Field, ErrorMessage, FastField, FormikProps, useFormik 
} from 'formik';
// import * as Yup from 'yup';
import {toast} from 'react-toastify';
import '../styles/login.scss';
import 'font-awesome/css/font-awesome.min.css';



import {
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  // Redirect,
  useHistory,
  // useLocation
} from "react-router-dom";

const restClientApi = new ResClientApi();
const userService =  new UserService();
export default function Login(props) {
  // let history = useHistory();
  console.log('props', props);

  function connexion(param?){
      const methode = 'user/login';
      const data = {
        data: param
      };
      restClientApi.executePostApi(methode, data)
      .then((res)=>{
          if(res && res.data && !res.data.hasError){
            userService.saveUserData(res.data.items[0]);
            toast.success('Connexion réussie avec succès');
            props.history.push("/");
          } else {
              // history.push("/");
              toast.error(res.data.status.message);
          }
      })
      .catch((err)=>{
          toast.error(err);
      });
  }

  return (
    <div className="content-login">
      <div className="h-100">
        <div className="row h-100">
          <div className="col-md-6 col-lg-6 d-sm-flex bg-grey">
              <div className="row align-items-center justify-content-center w-100">
                <img className="w-65" src={require('../assets/img/@1x/login-img.png')} />
              </div>
          </div>
          <div className="col-md-6">
            <div className="row align-items-center justify-content-center w-100">
              <div className="w-500 form-side">
                <div className="text-center">
                  <img className="w-25" src={require('../assets/img/drawable-xxxhdpi/initial-logo.png')} />
                  <div className="text-light">By MCT</div>
                </div>
                <div className="mt-5">
                  <Formik
                            initialValues={{ email: '', password: '',backoffice: true }}
                            // validationSchema={ 
                            //   Yup.object().shape({
                            //     firstName: Yup.string()
                            //       .min(2, 'Too Short!')
                            //       .max(50, 'Too Long!')
                            //       .required('Required'),
                            //     lastName: Yup.string()
                            //       .min(2, 'Too Short!')
                            //       .max(50, 'Too Long!')
                            //       .required('Required'),
                            //     email: Yup.string().email('Invalid email').required('Required'),
                            //   })
                            // }
                            validate={values => {
                              const errors = {};
                              if (!values.email) {
                                errors.email = 'Required';
                              } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                              ) {
                                errors.email = 'Invalid email address';
                              }
                              return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                              connexion(values);
                              // setTimeout(() => {
                              //   alert(JSON.stringify(values, null, 2));
                              //   setSubmitting(false);
                              // }, 400);
                            }}
                      >
                        {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group rounded-0 mt-5">
                          <div className="input-group">
                              <div className="input-group-prepend">
                                <div className="input-group-text border-right-none bg-white rounded-0">
                                  <i className="fa fa-user fa-14" aria-hidden="true"></i>
                                </div>
                              </div>
                            <input type="email" className="form-control rounded-0 border-left-none" id="loginId"
                              aria-describedby="emailHelp" name="email"
                              onChange={handleChange}
                              value={values.email}
                            />
                          </div>
                          {errors.email && touched.email && errors.email}
                        </div>
                        <div className="form-group rounded-0 mt-5 mb-0">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text border-right-none bg-white rounded-0">
                                <i className="fa fa-lock fa-14" aria-hidden="true"></i>
                              </div>
                            </div>
                            <input type="password" className="form-control rounded-0 border-left-none" id="passwordId"
                              onChange={handleChange}
                              name="password"
                              value={values.password}
                            />
                          </div>
                          {errors.password && touched.password && errors.password}
                        </div>
                        <div className="text-right mb-4">
                            <a className="text-dark font-size-small">Mot de passe oublié?</a>
                        </div>
                        <button type="submit" className="btn btn-connexion rounded-0 px-4 w-100">Connexion</button>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}